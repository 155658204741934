.kanban-container {
  display: flex;
  height: calc(100vh - 165px);
}

.center {
  align-self: center;
  text-align: center;
}

.column-title {
  h4 {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 500;
  }
  margin: 8px;
  margin-bottom: 0px;
  padding: 10px;
  background: whitesmoke;
  border: 1px solid #d3d3d3;
  border-bottom: none;
  border-radius: 5px 5px 0px 0px;
}

.item {
  user-select: none;
  margin: 0 0 12px 0;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.list {
  padding: 13px;
  padding-bottom: 150px;
  border-radius: 0px 0px 5px 5px;
  margin: 8px;
  margin-top: 0px;
  width: 310px;
  height: calc(100vh - 225px);
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid #d3d3d3;
  border-top: none;
}

.popup-content {
  white-space: pre-line;
  max-height: 87px;
  min-height: 30px;
  overflow-y: scroll;
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
}

@primary-color: #3645BF;@dark-primary-color: #535be2;@light-background-color: #f5f5f5;@secondary-color: #BB945B;@congo-pink: #FE938C;@link-color: #0B233F;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);@desktop: -1;@tablet: -1;@mobile: (max-width: 480px);@textStyles: [object Object];@fontSizes: 12,13,14,15,16,18,20,21,22,24,26,28,32,36,52;@breakpoints: 319px,599px;@colours: [object Object];