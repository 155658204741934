// .ant-table-thead > tr > th

.hc-table {
  .ant-table-small {
    tr {
      td {
        font-size: 15px;
      }
      th {
        font-size: 15px;
      }
    }
  }
  .ant-table-middle {
    tr {
      th {
        padding: 12px !important;
      }
    }
  }
  tr {
    td {
      border-color: #dde1e3 !important;
    }
    th {
      background: whitesmoke;
      border: 1px solid #b7b6b6;
      font-weight: 400;
    }
  }
  .ivory-bg {
    background: ivory;
  }
}

.excel-like .ant-table-thead .ant-table-cell {
  background: #207346;
  color: white;
}

@primary-color: #3645BF;@dark-primary-color: #535be2;@light-background-color: #f5f5f5;@secondary-color: #BB945B;@congo-pink: #FE938C;@link-color: #0B233F;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);@desktop: -1;@tablet: -1;@mobile: (max-width: 480px);@textStyles: [object Object];@fontSizes: 12,13,14,15,16,18,20,21,22,24,26,28,32,36,52;@breakpoints: 319px,599px;@colours: [object Object];