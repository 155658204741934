.candidate-profile {
  min-height: calc(100% - 70px);
}

.candidate-timeline {
  .ant-timeline {
    margin-left: 100px;
  }
  .ant-timeline-item {
    padding-bottom: 40px;
  }
}

.form-layout {
  row-gap: 0px;
  width: 85%;
  background: white;
  margin: 0px auto;
  padding: 30px;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
}

.site-tag-plus {
  background: #fff;
  border-style: dashed;
}
.edit-tag {
  user-select: none;
}
.tag-input {
  width: 180px;
  margin-right: 8px;
  vertical-align: top;
}

.security-modal {
  .ant-modal-body,
  .ant-modal-footer {
    background-color: floralwhite;
  }
}

.green-box {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #7dbb25;
  }
}

@primary-color: #3645BF;@dark-primary-color: #535be2;@light-background-color: #f5f5f5;@secondary-color: #BB945B;@congo-pink: #FE938C;@link-color: #0B233F;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);@desktop: -1;@tablet: -1;@mobile: (max-width: 480px);@textStyles: [object Object];@fontSizes: 12,13,14,15,16,18,20,21,22,24,26,28,32,36,52;@breakpoints: 319px,599px;@colours: [object Object];