@import '~antd/dist/antd.less';

::selection {
  background-color: #b5d7fe;
  color: black;
}

#root {
  height: 100vh;
}

.center-children {
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout {
  height: inherit;
}

.anchor_link,
a {
  color: @primary-color;
  text-decoration: underline;
}

.app-container {
  height: 100%;
}

/* Overwrite Antd design global styles */

.ant-modal-content {
  border-radius: 5px;
  .ant-modal-header {
    border-radius: 5px 5px 0px;
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
}

.ant-select-disabled {
  .ant-select-selector {
    color: #333 !important;
  }
  &.ant-select-multiple {
    .ant-select-selection-item {
      background: white;
      color: #333;
    }
  }
}

.primary-color {
  color: @primary-color;
}

// make checkbox border more visible
.ant-checkbox-inner {
  border: 1px solid #333;
}

.ant-modal-footer,
.ant-modal {
  border-radius: 5px;
}

@primary-color: #3645BF;@dark-primary-color: #535be2;@light-background-color: #f5f5f5;@secondary-color: #BB945B;@congo-pink: #FE938C;@link-color: #0B233F;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);@desktop: -1;@tablet: -1;@mobile: (max-width: 480px);@textStyles: [object Object];@fontSizes: 12,13,14,15,16,18,20,21,22,24,26,28,32,36,52;@breakpoints: 319px,599px;@colours: [object Object];