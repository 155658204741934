.pending-forms-content {
  // 82px is the height of fix header
  height: calc(100vh - 82px);
  background: #f7f7f7;
  padding-top: 30px;
  padding-bottom: 100px;
  @media @mobile {
    padding: 16px;
  }
}

.active-form {
  border: 1px solid blue;
}

.meeting-feedback-form {
  .ant-form-item-label > label {
    font-size: 16px;
  }
  & .ant-collapse-header {
    @media @mobile {
      flex-direction: column;
      justify-content: center;
      .ant-collapse-extra {
        margin-left: unset !important;
      }
    }
  }
  & .attendee-title {
    margin-bottom: unset;
    @media @mobile {
      margin-bottom: 5px;
    }
  }
  & .attendees-collapse {
    .ant-collapse-content-box {
      padding: unset;
    }
  }
}

.missing-info {
  padding: 16px 16px 0px 16px;
  margin-bottom: 10px;
  background-color: ivory;
  border: 1px solid #d3d37e;
  margin: 8px !important;
}

.pending-form-timeline::-webkit-scrollbar {
  display: none;
}

.pending-form-timeline {
  width: 80vw;
  height: 60vh;
  margin: auto;
  overflow: scroll;
  margin-top: -25px;

  .ant-timeline {
    margin-left: 100px;
  }
  .ant-timeline-item {
    padding-bottom: 40px;
    .highlight {
      span {
        border-bottom: 2px solid #47c616;
      }
    }
  }
}

.center-fullscreen {
  justify-content: center;
  align-items: center;
}

.splash {
  position: fixed;
  z-index: 100;
}

.timeline-event {
  left: -115px;
  position: absolute;
  text-align: right;
}

@primary-color: #3645BF;@dark-primary-color: #535be2;@light-background-color: #f5f5f5;@secondary-color: #BB945B;@congo-pink: #FE938C;@link-color: #0B233F;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);@desktop: -1;@tablet: -1;@mobile: (max-width: 480px);@textStyles: [object Object];@fontSizes: 12,13,14,15,16,18,20,21,22,24,26,28,32,36,52;@breakpoints: 319px,599px;@colours: [object Object];